import gql from 'graphql-tag'
import { appClient } from '@/plugins/vue-apollo.plugin'
import { VueGQLType } from '@/common/custom/vue-apollo-customization'
import { OrderImport } from '@/models/order/Order'
import { CreateOrderWithNumberResult, UpdateOrderInput, UpdateOrderResult } from '../schema/app.graphql.types'
import { ulog } from '@/common/utils/AppConsole'

export namespace MutateOrder {

  export namespace Create {
    export type Type = VueGQLType<Data, any>

    const mutation = gql`
      mutation OrderCreate {
        createOrderWithNumber {
          success, output { id, key, fullpath, orderNumber, orderType, state, assetFolderId }
        }
      }
    `

    export type Data = {
      createOrderWithNumber: CreateOrderWithNumberResult
    }

    export const procedure = async () => appClient
      .mutate<Data>({ mutation })
      .then(r => r.data?.createOrderWithNumber?.success && r.data?.createOrderWithNumber.output
        ? OrderImport(r.data?.createOrderWithNumber.output)
        : ulog('e', 'MutateOrder(C) procedure failed', r)
      )
      .catch(e => ulog('e', 'MutateOrder(C) procedure exception', e))

  }

  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---
  // ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

  export namespace Update {
    export type Type = VueGQLType<Data, Variables>

    const mutation = gql`
      mutation OrderUpdate($fullpath: String!, $values: UpdateOrderInput) {
        updateOrder(fullpath: $fullpath, input: $values) {
          success
          output {
            id, key, fullpath,
            orderNumber, orderType, state,

            invoicingData {
              ...on object_InvoicingData {
                id, key, fullpath,

                invoicedEntity, directSell,
                firstName, lastName, verificationType, birthdate,
                companyName, ico, dic,
                street, streetNumber, city, postcode,
                vatRate, liability, paymentMethod
              }
            },

            contactData {
              ... on object_ContactData {
                id, key, fullpath,
                firstName, lastName, phone, email, note
              }
            },

            configurations {
              ... on object_OrderDoorConfiguration {
                id, key, fullpath,
                name, directSellTransport, quantity, note,
                shippingStreet, shippingStreetNumber, shippingCity, shippingPostcode,
                street, streetNumber, city, postcode, floor, elevator,
                parameters, additionalServices, otherAdditionalServices,
                priceItems, installationDate, installationState,
                installationMorning, installationAfternoon, installationEvening,

                protocolData {
                  ...on object_ProtocolData {
                    id, key, fullpath,
                    serialNumber, peepholeHeight, doorHeight, confirmations, clientNotes,
                    protocolCreation, authorizedPerson, authorizedPersonID,

                    protocol { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
                    signatureClient { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
                    signatureWorker { ...on asset { id, fullpath, filename, filesize, type, mimetype } },

                    isMagnum, isCertified,
                  }
                },

                attachments { ... on asset { id, fullpath, filename, filesize, type, mimetype } },

                selectedConfiguration
                selectedConfigurationApp
                selectedConfigurationWeb
                selectedPrice

                workerGroup {
                  ... on object_WorkerGroup {
                    id, key, fullpath,
                    workerGroupID, availableMorning, availableAfternoon, availableEvening,

                    leadingWorker {
                      ...on object_Worker {
                        id, key, fullpath,
                        firstName, lastName, phone, email
                      }
                    },

                    workers {
                      ...on object_Worker {
                        id, key, fullpath,
                        firstName, lastName, phone, email
                      }
                    },
                  }
                }
              }
            },

            configuratorVersion,
            creationDateInquiry, creationDateOrder, invoiceNumber,
            invoiceState,
            invoiceAsset {
              ... on asset { id, fullpath, filename, filesize, type, mimetype }
            },

            invoiceProformaState,
            invoiceProformaAsset {
              ... on asset { id, fullpath, filename, filesize, type, mimetype }
            },

            branchOffice {
              ... on object_BranchOffice {
                id, key, fullpath,
              }
            },

            branchCommissionState, isPriority, isEmergency, internalNote, cancellationNote,
          }
        }
      }
    `

    export type Variables = {
      fullpath: string
      values: UpdateOrderInput
    }

    export type Data = {
      updateOrder: UpdateOrderResult
    }

    export const procedure = (variables: Variables) => appClient
      .mutate<Data, Variables>({ mutation, variables })
      .then(r => r?.data?.updateOrder.success && r.data.updateOrder.output
        ? OrderImport(r.data.updateOrder.output)
        : ulog('e', 'MutateOrder(U) procedure failed:', r)
      )
      .catch(e => ulog('e', 'MutateOrder(U) exception', e))

  }

}
