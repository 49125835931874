import gql from 'graphql-tag'

export default gql`
query OrderQuery($fullpath: String!) {
  getOrder(fullpath: $fullpath) {
    id, key, fullpath,
    orderNumber, orderType, state, assetFolderId,
    customerCalledAt,
    createdBy {
      ... on object_ApiUser {
          id
          fullpath
          key
          name
          email
      }
    }
    acceptedBy {
      ... on object_ApiUser {
          id
          fullpath
          key
          name
          email
      }
    }

    invoicingData {
      ...on object_InvoicingData {
        id, key, fullpath,
        invoicedEntity, directSell,
        firstName, lastName, verificationType, birthdate,
        companyName, ico, dic,
        street, streetNumber, city, postcode,
        vatRate, liability, paymentMethod
      }
    },

    contactData {
      ... on object_ContactData {
        id, key, fullpath,
        firstName, lastName, phone, email, note
      }
    },

    configurations {
      ... on object_OrderDoorConfiguration {
        id, key, fullpath,
        name, directSellTransport, quantity, note,
        shippingStreet, shippingStreetNumber, shippingCity, shippingPostcode,
        street, streetNumber, city, postcode, floor, elevator,
        parameters, additionalServices, otherAdditionalServices, priceItems,
        installationDate, installationState, installationMorning, installationAfternoon, installationEvening,
        attachments { ... on asset { id, fullpath, filename, filesize, type, mimetype } },
        attachmentFolderId,
        documents { ... on asset { id, fullpath, filename, filesize, type, mimetype } },
        documentFolderId,
        selectedDoorLabel,
        selectedDoorLabelExtended,
        selectedFrame,
        selectedModel,
        workerGroup {
          ... on object_WorkerGroup {
            id, key, fullpath,
            workerGroupID, availableMorning, availableAfternoon, availableEvening, certificate,

            leadingWorker {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },

            workers {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },
          }
        },

        protocolData {
          ...on object_ProtocolData {
            id, key, fullpath,
            serialNumber, peepholeHeight, doorHeight, confirmations, clientNotes,
            protocolCreation, authorizedPerson, authorizedPersonID,

            protocol { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
            installationProtocol { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
            signatureClient { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
            signatureWorker { ...on asset { id, fullpath, filename, filesize, type, mimetype } },

            isMagnum, isCertified,
            documentsFolderId,
            assemblyPictures { ...on asset { id, fullpath, filename, filesize, type, mimetype } },
          }
        }

        selectedConfiguration
        selectedConfigurationApp
        selectedConfigurationWeb
        selectedPrice

      }
    },

    configuratorVersion,

    creationDateInquiry, creationDateOrder, invoiceNumber,
    invoiceState,
    invoiceAsset {
      ... on asset { id, fullpath, filename, filesize, type, mimetype }
    },

    invoiceProformaState,
    invoiceProformaAsset {
      ... on asset { id, fullpath, filename, filesize, type, mimetype }
    },

    branchOffice {

      ... on object_BranchOffice {
        id, key, fullpath,
        name, applicationMode, code, address, city,
        commission, expedition,
        businessRegisterName, billingCity, billingPostalCode,
        companyIdNumber, companyTaxNumber,
        billingRegisteredIn, url,
        stampAsset {
          ...on asset { id, fullpath, filename, filesize, type, mimetype }
        },

        employees {
          ...on fieldcollection_emloyee {
            name, position, phone, email,
            photo {
              ...on hotspotimage {
                image {
                  ...on asset { id, fullpath, filename, filesize, type, mimetype }
                }
              }
            }
          }
        }

        parentBranchOffice {
          ...on object_BranchOffice {
            id, key, fullpath
            }
        }

        location {
          ...on Geopoint {
            longitude, longitude
          }
        },

        holidayFrom,
        holidayTill,
        morningAssemblyTime,
        afternoonAssemblyTime,
        eveningAssemblyTime,

        workerGroups {
          ... on object_WorkerGroup {
            id, key, fullpath,
            workerGroupID, availableMorning, availableAfternoon, availableEvening, certificate,

            leadingWorker {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },

            workers {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },
          }
        }

        apiUser {
          ...on object_ApiUser {
            id, key, fullpath,
            name, email, passwordResetHash,
          }
        }

      }
    },

    signedOrderConfirmation { ...on asset { id, fullpath, filename, filesize, type, mimetype } }

    allocatedBranch {

      ... on object_BranchOffice {
        id, key, fullpath,
        name, applicationMode, code, address, city,
        commission, expedition,
        businessRegisterName, billingCity, billingPostalCode,
        companyIdNumber, companyTaxNumber,
        billingRegisteredIn, url,
        stampAsset {
          ...on asset { id, fullpath, filename, filesize, type, mimetype }
        },

        employees {
          ...on fieldcollection_emloyee {
            name, position, phone, email,
            photo {
              ...on hotspotimage {
                image {
                  ...on asset { id, fullpath, filename, filesize, type, mimetype }
                }
              }
            }
          }
        }

        parentBranchOffice {
          ...on object_BranchOffice {
            id, key, fullpath
            }
        }

        location {
          ...on Geopoint {
            longitude, longitude
          }
        },

        holidayFrom,
        holidayTill,
        morningAssemblyTime,
        afternoonAssemblyTime,
        eveningAssemblyTime,

        workerGroups {
          ... on object_WorkerGroup {
            id, key, fullpath,
            workerGroupID, availableMorning, availableAfternoon, availableEvening, certificate,

            leadingWorker {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },

            workers {
              ...on object_Worker {
                id, key, fullpath,
                firstName, lastName, phone, email
              }
            },
          }
        }

        apiUser {
          ...on object_ApiUser {
            id, key, fullpath,
            name, email, passwordResetHash,
          }
        }

      }
    },

    history {
      ... on fieldcollection_history {
          action
          state
          datetime
          branchOffice {
              ... on object_BranchOffice {
                  id
                  key
                  code
                  city
                  name
                  __typename
              }
              __typename
          }
          apiUser {
              ... on object_ApiUser {
                  id
                  name
                  email
                  __typename
              }
              __typename
          }
          configuration {
              ... on object_OrderDoorConfiguration {
                  id
                  key
                  fullpath
                  name
              }
              __typename
          }
          workerGroup {
              ... on object_WorkerGroup {
                  id
                  key
                  fullpath
                  workerGroupID
              }
              __typename
          }
          installationState
          installationDate
          installationMorning
          installationAfternoon
          installationEvening
          oldWorkerGroup {
            ... on object_WorkerGroup {
              id
              key
              fullpath
              workerGroupID
            }
            __typename
          }
          oldInstallationState
          oldInstallationDate
          oldInstallationMorning
          oldInstallationAfternoon
          oldInstallationEvening
          __typename
      }
      __typename
    }

    branchCommissionState, isPriority, isEmergency, internalNote, cancellationNote, totalPriceWithVat, totalPriceWithoutVat
    invoice {
        ... on object_Invoice {
            id
            proformaColor
            invoiceColor
            workflowState
            proformaLabel
            invoiceLabel
            proforma {
                ... on asset {
                    id
                    fullpath
                    filename
                    filesize
                    type
                    mimetype
                    __typename
                }
                __typename
            }
            invoice {
                ... on asset {
                    id
                    fullpath
                    filename
                    filesize
                    type
                    mimetype
                    __typename
                }
                __typename
            }
        }
    }
  }
}
`
