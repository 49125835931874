import { PimcoreRelation } from './../pimcore/PimcoreRelation'
import { Maybe, Object_BranchOffice, UpdateBranchOfficeInput } from '@/api/graphql/schema/app.graphql.types'
import { get, ObjectParams } from '@/common/custom/ts-customization'
import { PimcoreApiUser } from '../pimcore/PimcoreApiUser'
import { PimcoreAsset } from '../pimcore/PimcoreAsset'
import { PimcoreNode } from '../pimcore/PimcoreNode'
import { WorkerGroup } from '../workers/WorkerGroup'
import { PimcoreGeopoint } from './Geopoint'
import i18nPlugin from '@/plugins/i18n.plugin'

export enum BRANCH_ID {
  CENTRAL = 406
}

export enum BRANCH_CODE {
  CENTRAL = '101'
}
export enum BRANCH_APPLICATION_TYPE {
  INVOICE_HT_ASSEMBLY_BRANCH = 'invoice_ht__assembly_branch',
  INVOICE_HT_ASSEMBLY_HT = 'invoice_ht__assembly_ht',
}


export type BranchApplicationMode =
  | ''
  | BRANCH_APPLICATION_TYPE.INVOICE_HT_ASSEMBLY_BRANCH
  | BRANCH_APPLICATION_TYPE.INVOICE_HT_ASSEMBLY_HT

export const BranchApplicationModePrint = (t: BRANCH_APPLICATION_TYPE) => {
  switch (t) {
    case 'invoice_ht__assembly_branch':     return i18nPlugin.t('branch.types.subordinateBranch').toString()
    case 'invoice_ht__assembly_ht':         return i18nPlugin.t('branch.types.superordinateBranch').toString()
    default: return ''
  }
}


// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---



export type BranchExpeditionTerm =
  | ''
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'

export const BranchExpeditionTermPrint = (t: BranchExpeditionTerm) => {
  switch (t) {
    case 'monday':    return 'Pondělí'
    case 'tuesday':   return 'Úterý'
    case 'wednesday': return 'Středa'
    case 'thursday':  return 'Čtvrtek'
    case 'friday':    return 'Pátek'
    default: return ''
  }
}

// ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---  ---

export type BranchOffice = PimcoreNode & {

  // Branch Data
  applicationMode: BranchApplicationMode
  name: string

  /** ciselny kod pobocky */
  code: string
  address: string
  city: string

  phone: string
  email: string

  commission: number
  expedition: BranchExpeditionTerm

  // Billing Information
  businessRegisterName: string
  billingResidence: string
  billingCity: string
  billingPostalCode: string
  companyIdNumber: string
  companyTaxNumber: string
  billingRegisteredIn: string

  // Other Data
  url: string
  stampAsset: PimcoreAsset

  employees: unknown
  location: PimcoreGeopoint

  openingHours: string
  holidayFrom: string
  holidayTill: string
  morningAssemblyTime: string | null
  afternoonAssemblyTime: string | null
  eveningAssemblyTime: string | null

  workerGroups: WorkerGroup[]
  apiUser?: PimcoreApiUser
  apiUsers?: PimcoreApiUser[]
  parentBranchOffice?: BranchOffice | null
  isWholesale?: boolean
  branchType: Maybe<string>
  parentName: Maybe<string>
}
export const BranchOffice = (o?: Maybe<Object_BranchOffice> | undefined): BranchOffice => ({
  ...PimcoreNode(o?.id, o?.key, o?.fullpath),

  applicationMode: get<BranchApplicationMode>(o?.applicationMode, ''),
  name: o?.name || '',

  code: o?.code || '',
  address: o?.address || '',
  city: o?.city || '',

  phone: o?.phone || '',
  email: o?.email || '',

  commission: o?.commission || 0,
  expedition: get(o?.expedition, ''),

  businessRegisterName: o?.businessRegisterName || '',
  billingResidence: o?.billingResidence || '',
  billingCity: o?.billingCity || '',
  billingPostalCode: o?.billingPostalCode || '',

  companyIdNumber: o?.companyIdNumber || '',
  companyTaxNumber: o?.companyTaxNumber || '',
  billingRegisteredIn: o?.billingRegisteredIn || '',

  url: o?.url || '',
  stampAsset: PimcoreAsset(o?.stampAsset),

  employees: o?.employees || undefined,
  location: PimcoreGeopoint(o?.location),

  openingHours: o?.openingHours || '',
  holidayFrom: o?.holidayFrom || '',
  holidayTill: o?.holidayTill || '',
  morningAssemblyTime: o?.morningAssemblyTime || null,
  afternoonAssemblyTime: o?.afternoonAssemblyTime || null,
  eveningAssemblyTime: o?.eveningAssemblyTime || null,

  workerGroups: o?.workerGroups ? o.workerGroups.map(i => WorkerGroup(i)) : [],
  apiUser: o?.apiUser ? PimcoreApiUser(o.apiUser) : undefined,
  apiUsers: o?.apiUsers ? o.apiUsers.map(i => PimcoreApiUser(i)) : [],
  parentBranchOffice: o?.parentBranchOffice ? BranchOffice(o.parentBranchOffice) : null,
  isWholesale:  o?.isWholesale || false,
  branchType: o?.branchType || null,
  parentName: o?.parentName || null,
})


export const BranchOfficeExport = (b: BranchOffice): UpdateBranchOfficeInput => {
  const exported: UpdateBranchOfficeInput = {}
  for (const k of ObjectParams(b)) {
    switch (k) {
      case 'workerGroups':  exported[k] = b.workerGroups.map(i => PimcoreRelation('object', i)!); break
      case 'stampAsset':    exported[k] = PimcoreRelation('asset', b.stampAsset)!; break
      case 'apiUsers':  break

      // TODO: IDK WHAT TO DO WITH EMPLOYEES. @ANDREJ
      case 'employees': break

      // do not include apiUser
      case 'apiUser': break

      // not part of input data
      case 'id': break
      case 'fullpath': break

      case 'commission':  exported[k] = parseFloat(b.commission.toString()); break

      default: (exported[k] as any) = b[k]
    }

    if (!exported[k] && typeof exported[k] !== 'boolean') delete exported[k]
  }

  return exported
}

export const BranchOfficeWorkerGroupsUpdate = (office: BranchOffice): UpdateBranchOfficeInput => {
  const data = BranchOfficeExport(office)
  delete data.branchType

  return data
}
